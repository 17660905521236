<template>
	<div>
		<div class="logo">
			<img src="/static/img/sole_tracking.png" alt="">
		</div>
		
		<section id="register" class="register">
			<div class="container">

				<div class="section-title">
					<h2>AVISO DE PRIVACIDAD SoLe Tracking</h2>
				</div>

				<div class="col-10 offset-1">
					
<p>En cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de los
Particulares (LFPDPPP) y demás normativas aplicables en México y Latinoamérica, SoLe
Tracking garantiza la protección y privacidad de los datos personales proporcionados por los
usuarios de nuestro dispositivo localizador GPS.</p>

<p>1. Responsable del Tratamiento de Datos Personales
SoLe Tracking es responsable del tratamiento de los datos personales recabados mediante el
uso del dispositivo GPS. Si tienes alguna duda o solicitud respecto al manejo de tus datos
personales, puedes contactarnos en contacto@soletracking.com o al 3331015825.</p>
<p>2. Datos Personales que Recopilamos
Al utilizar nuestro dispositivo de localización GPS, podemos recopilar los siguientes datos:
	 <ul>
		<li>Datos de ubicación geográca: Historial de ubicaciones y movimientos en tiempo real.</li>
		<li>Datos del usuario registrado: Nombre, correo electrónico, teléfono y, en caso de ser necesario, datos del tutor o responsable (en el caso de menores de edad o personas con alguna condición que lo requiera).</li>
		<li>Datos técnicos: Identicadores del dispositivo, modelo y sistema operativo.</li>
	 </ul>
	 
	 </p>
<p>3. Finalidades del Tratamiento de los Datos
Los datos personales se tratarán para las siguientes nalidades:
<ul>
	<li>Primarias (indispensables para el servicio):</li>
	<li>Proveer la funcionalidad del dispositivo GPS para la localización en tiempo real.</li>
	<li>Garantizar la seguridad del usuario a través de alertas de emergencia, geocercas y
	noticaciones.</li>
	<li>Generar reportes personalizados, como historial de movimiento y rutas.</li>
	<li>Secundarias (opcional y con tu consentimiento):</li>
	<li>Enviar comunicaciones sobre actualizaciones del dispositivo y servicios relacionados.</li>
</ul></p>
<p>4. Tratamiento de Datos para Personas con Discapacidad o Condiciones
Especiales
Nuestro dispositivo está diseñado para beneciar también a personas con discapacidad y
enfermedades como Alzheimer, demencia u otras condiciones que requieran un monitoreo
constante.
<ul>
	<li>	En estos casos, el tratamiento de datos se realiza con el consentimiento del tutor o
	responsable legal.</li>
	<li>	La información recabada se usará exclusivamente para garantizar la seguridad y
	localización de la persona, evitando extravíos y facilitando la intervención en caso de
	emergencia.</li>
	<li>	Nos comprometemos a proteger estos datos bajo estrictas medidas de seguridad y
	condencialidad.</li>
</ul></p>
<p>5. Tratamiento de Datos Personales de Menores de Edad
En el caso de dispositivos utilizados por niños, el consentimiento deberá ser otorgado por el
padre, madre o tutor legal. Nos comprometemos a proteger esta información de manera
estricta y a tratarla únicamente con nes de seguridad y localización.</p>
<p>6. Transferencia de Datos Personales
No compartimos tus datos personales con terceros, salvo en los siguientes casos:
<ul>
	<li>Autoridades competentes, cuando sea requerido por disposiciones legales o en
	situaciones de emergencia.</li>
	<li>Proveedores de servicios necesarios para la operación del dispositivo (por ejemplo,
	mantenimiento de plataformas o servidores), quienes están sujetos a acuerdos de
	condencialidad y seguridad.</li>
</ul></p>
<p>7. Medidas de Seguridad
Hemos implementado medidas técnicas, administrativas y físicas para proteger tus datos
personales contra pérdida, uso indebido, acceso no autorizado, alteración y destrucción. Esto
incluye el cifrado de datos y accesos restringidos.</p>
<p>8. Derechos ARCO
Como titular de los datos personales, tienes derecho a:
<ul>
	<li>Acceder a tus datos personales.</li>
	<li>Recticar datos incorrectos o incompletos.</li>
	<li>Cancelar el uso de tus datos personales, cuando proceda.</li>
	<li>	Oponerte al tratamiento de tus datos para nes especícos.
	Puedes ejercer estos derechos enviando una solicitud a contacto@soletracking.com,
	indicando tu nombre completo, datos de contacto y una descripción clara del derecho que
	deseas ejercer. Responderemos a tu solicitud dentro de los plazos establecidos por la
	LFPDPPP.</li>
</ul></p>
<p>9. Cambios al Aviso de Privacidad
Nos reservamos el derecho de realizar cambios o actualizaciones al presente Aviso de
Privacidad para reejar cambios en nuestras prácticas o en la normativa aplicable.
Noticaremos cualquier modicación a través de nuestro sitio web o mediante noticaciones
directas al usuario.
Última actualización: Noviembre 18, 2024.</p>
				</div>
			</div>
		</section>
	</div>		
</template>
<script type="text/javascript"> 
	export default { 
		data(){ 
			return {
			
			}
		}, 
		created() { 
			//var instance = this;
		}, 
		methods: { 
			/*Funcion que esta conectada a la funcion de*/
		} 
	} 
</script>
<style scoped type="text/css" src="../../assets/css/style.css"></style>